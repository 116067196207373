@tailwind base;
@tailwind components;
@tailwind utilities;
@import "nprogress/nprogress.css";
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --font-primary: "Inter", sans-serif;

    --foreground-red: #e76e50;
    --foreground-green: #2a9d90;

    --color-scheme: light;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --marketplace-brown: rgba(29, 29, 29, 1);
    --marketplace-green: rgba(39, 154, 142, 1);
    --marketplace-fire: rgba(233, 30, 99, 1);
    --marketplace-red: rgba(233, 50, 30, 1);
    --marketplace-blue: rgba(49, 106, 205, 1);
    --marketplace-blue-new: #2252cc;
    --marketplace-grey: #969696;
  }

  .dark {
    --background: 219 62% 8%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --color-scheme: dark;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
  img {
    @apply inline-block;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

body {
  font-family: var(--font-primary);
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "@/components/multi-range-slider/multi-range-slider.css";
@import "./countryCodeCustom.css";

@import "./input-range.css";
@import "./html-inputs.css";
@import "./custom.css";

.loader {
  width: 60px;
  aspect-ratio: 3;
  --_g: no-repeat radial-gradient(circle closest-side, #18438a 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.percentage .tooltip-value::after {
  content: "%";
}
.radialChartMarketplace .tooltip-value::after {
  content: "%";
}
.recharts-tooltip-wrapper div {
  /* display: block !important; */
  /* content: "%"; */
}
/*
.dollar .tooltip-value::before {
  content: "$";
}
#section-one-chart .recharts-surface {
  /* margin-top: 5px; */
/* } */
/* */
#section-one-chart .recharts-wrapper {
  padding: 15px;
  /* margin-top: 5px; */
}
.react-flow__controls {
  /* box-shadow: 0 0 4px 1px hsl(var(--input)) !important; */
  box-shadow: none !important;
  border: 1px solid hsl(var(--input));
  color: hsl(var(--foreground));
  fill: hsl(var(--foreground));
}
.react-flow__controls-button {
  /* color: hsl(var(--foreground)) !important; */
  /* fill: hsl(var(--foreground)) !important; */
  background: hsl(var(--background)) !important;
}
.react-flow__controls-button:hover {
  background: hsl(var(--accent)) !important;
  /* opacity: 0.8; */
}
.react-flow__panel.right {
  display: none;
}

.sortFilterMarketplace .focus\:ring-ring:focus {
  outline: none !important;
  outline-offset: 0 !important;
  --tw-ring-color: none !important;
}
.sortFilterMarketplace button .lucide {
  display: none !important;
}
.marketplace-filter-icon {
  filter: invert(48%) sepia(71%) saturate(419%) hue-rotate(124deg)
    brightness(92%) contrast(89%);
}
.marketplace-filter-icon-dark {
  filter: brightness(0) saturate(100%) invert(7%) sepia(88%) saturate(1848%)
    hue-rotate(208deg) brightness(50%) contrast(120%);
}
#tv-attr-logo {
  display: none;
}
#marketplace-chart .recharts-wrapper {
  padding: 15px;
  margin-left: -15px;
}
#section1-chart .recharts-wrapper {
  padding: 10px;
  /* object-fit: contain; */
  margin-left: -10px;
  /* overflow: visible !important; */
}
#section1-chart .recharts-wrapper svg {
  overflow: visible;
}
#section1-chart .recharts-wrapper tspan {
  font-size: 13px;
  @media screen and (min-width: 1500px) {
    font-size: 9px;
  }
}
